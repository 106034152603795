import * as React from 'react'
import { graphql } from 'gatsby'
import { useQueryParam, ArrayParam, withDefault } from 'use-query-params'
import PropTypes from 'prop-types'

import Layout from '../../components/layout'

import PortfolioList from '../../components/portfolio/list'
import PortfolioFilters from '../../components/portfolio/filters'

const IndustryPage = ({ data }) => {
	const portfolio = data.strapi.portfolioPage
	const industries = data.strapi.industries

	// Active State for Enabled Filters
	const [active, setActive] = useQueryParam('ind', withDefault(ArrayParam, []))

	// Add Fitlers to Active Array
	const toggleActive = filter => {
		setActive(prevState => {
			let nextState = [...prevState]

			if (!prevState.includes(filter)) {
				nextState.push(filter)
			} else {
				nextState = nextState.filter(state => state !== filter)
			}

			return nextState
		})
	}

	return (
		<Layout page={portfolio} metadata={portfolio.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Portfolio Industry Filters */}
				<section className="pt-20 lg:pt-40 pb-40 sm:pb-60 md:pb-80">
					<PortfolioFilters
						filters={industries}
						toggle={toggleActive}
						active={active}
						all={true}
					/>
				</section>

				{/* Small Project Cards in Order */}
				<section className="pb-40 sm:pb-60 md:pb-80 lg:pb-100 xl:pb-120">
					<PortfolioList order={portfolio.order} active={active} />
				</section>
			</div>
		</Layout>
	)
}

IndustryPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			portfolioPage: PropTypes.shape({
				order: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
			industries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		}).isRequired,
	}).isRequired,
}

export const query = graphql`
	{
		strapi {
			portfolioPage {
				order {
					id
					project {
						slug
						title
						subtitle
						industries {
							slug
							title
						}
						categories {
							slug
							title
						}
						thumbnail {
							url
							ext
							height
							width
							name
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 360
										height: 270
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										formats: [AUTO, WEBP, AVIF]
									)
								}
							}
						}
					}
				}
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
			industries {
				slug
				title
			}
		}
	}
`

export default IndustryPage
